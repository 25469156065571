import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

const Chats = () => {
  const [chats, setChats] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  // Request notification permission as soon as the component mounts
  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then(permission => {
        console.log("Notification permission:", permission);
      });
    }
  }, []);

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        const chatData = doc.data() || {};
        setChats(chatData);

        // Check for new messages and trigger notifications if necessary
        Object.entries(chatData).forEach(([chatId, chat]) => {
          const lastMessage = chat?.messages?.[chat.messages.length - 1];

          // Send notification if the message is from someone else
          if (lastMessage && lastMessage.senderId !== currentUser.uid) {
            if (Notification.permission === "granted") {
              new Notification(`${chat.userInfo.displayName} sent you a message!`, {
                body: lastMessage.text,
                icon: chat.userInfo?.photoURL || "/default-avatar.png",
              });
            }
          }
        });
      });

      return () => {
        unsub();
      };
    };

    if (currentUser?.uid) {
      getChats();
    }
  }, [currentUser]);

  const handleSelect = (u) => {
    dispatch({ type: "CHANGE_USER", payload: u });
  };

  return (
    <div className="chats">
      {Object.entries(chats)
        .filter(([chatId, chat]) => {
          // Exclude chats where the current user is involved, whether as the sender or receiver
          return chat?.userInfo?.uid !== currentUser.uid; // Filter out chats where userInfo.uid is the current user's UID
        })
        .filter(([chatId, chat]) => chat?.messages && chat.messages.length > 0) // Ensure there are messages in the chat
        .sort((a, b) => {
          const lastMessageA = a[1]?.messages?.[a[1].messages.length - 1];
          const lastMessageB = b[1]?.messages?.[b[1].messages.length - 1];

          const dateA = lastMessageA?.date?.seconds || 0;
          const dateB = lastMessageB?.date?.seconds || 0;

          return dateB - dateA; // Sort by most recent message
        })
        .map(([chatId, chat]) => {
          const lastMessage = chat?.messages?.[chat.messages.length - 1];
          let lastMessageText = "No messages yet";

          // Check if the last message was sent by the current user
          if (lastMessage?.senderId === currentUser.uid) {
            lastMessageText = `You: ${lastMessage.text}`;
          } else {
            lastMessageText = lastMessage?.text || "No messages yet";
          }

          return (
            <div
              className="userChat"
              key={chatId}
              onClick={() => handleSelect(chat.userInfo)}
            >
              <img
                src={chat.userInfo?.photoURL || "/default-avatar.png"}
                alt={chat.userInfo?.displayName || "User"}
              />
              <div className="userChatInfo">
                <span>{chat.userInfo?.displayName}</span> {/* Display name of the other user */}
                <p className="lastMessageText">{lastMessageText}</p> {/* Display the last message */}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Chats;
