import React, { useContext } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { AuthContext } from "../context/AuthContext";
import User from "../img/user.png";

const Navbar = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="navbar">
      <span className="logo">AVACS Chat</span>
      <div className="user" style={styles.userContainer}>
        {/* User icon */}
        <img
          src={User}
          alt="User Icon"
          style={styles.userIcon}
        />
        {/* User display name */}
        <span style={styles.displayName}>{currentUser.displayName}</span>
        {/* Logout button */}
        <button style={styles.logoutButton} onClick={() => signOut(auth)}>
          Logout
        </button>
      </div>
    </div>
  );
};

// Inline styles for proper alignment and positioning
const styles = {
  userContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Spacing between elements
  },
  userIcon: {
    width: "32px",
    height: "32px",
    borderRadius: "50%", // Circular avatar
  },
  displayName: {
    fontWeight: "bold",
    fontSize: "16px",
    flex: 1, // Pushes the logout button to the right
    textAlign: "center",
  },
  logoutButton: {
    backgroundColor: "#ff4d4d",
    color: "#fff",
    border: "none",
    padding: "5px 10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default Navbar;
