import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyAD5M4iViNC720ZCzYe25YxE-KDQwLX8H4",
    authDomain: "chat-4ed04.firebaseapp.com",
    projectId: "chat-4ed04",
    storageBucket: "chat-4ed04.firebasestorage.app",
    messagingSenderId: "334427116808",
    appId: "1:334427116808:web:302847592918e2128a08bd"
  };

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()