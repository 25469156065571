import React, { useContext, useEffect, useState } from "react";
import User from "../img/user.png";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore"; 
import { db } from "../firebase";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";

const Search = () => {
  const [username, setUsername] = useState("");
  const [users, setUsers] = useState([]);
  const [err, setErr] = useState(false);
  const [userChats, setUserChats] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState({}); // Store unread message status for each chat

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  // Fetch users and user chats
  useEffect(() => {
    const fetchUsersAndChats = async () => {
      try {
        // Fetch users
        const querySnapshot = await getDocs(collection(db, "users"));
        const allUsers = [];
        querySnapshot.forEach((doc) => {
          allUsers.push(doc.data());
        });
        setUsers(allUsers);

        // Fetch user chats
        const userChatsSnapshot = await getDoc(doc(db, "userChats", currentUser.uid));
        const userChatsData = userChatsSnapshot.data() || {};
        setUserChats(Object.keys(userChatsData));
      } catch (err) {
        console.error("Error fetching data:", err);
        setErr(true);
      }
    };

    fetchUsersAndChats();
  }, [currentUser]);

  // Fetch unread messages based on the userChats
  useEffect(() => {
    const fetchUnreadMessages = async () => {
      try {
        // Fetch unread messages for each chat of the current user
        const unreadMessagesMap = {};
        for (let chatId of userChats) {
          const chatDoc = await getDoc(doc(db, "chats", chatId));
          const chatData = chatDoc.data();
          const lastMessage = chatData?.messages[chatData.messages.length - 1];

          // Check if the last message is unread and not from the current user
          if (lastMessage && lastMessage.read === false && lastMessage.senderId !== currentUser.uid) {
            unreadMessagesMap[chatId] = true;
          }
        }
        setUnreadMessages(unreadMessagesMap);
      } catch (err) {
        console.error("Error fetching unread messages:", err);
        setErr(true);
      }
    };

    if (userChats.length > 0) {
      fetchUnreadMessages();
    }
  }, [userChats, currentUser]); // Trigger unread message fetch when userChats changes

  const handleSelect = async (selectedUser) => {
    const combinedId =
      currentUser.uid > selectedUser.uid
        ? currentUser.uid + selectedUser.uid
        : selectedUser.uid + currentUser.uid;

    try {
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: selectedUser.uid,
            displayName: selectedUser.displayName,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", selectedUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }

      dispatch({ type: "CHANGE_USER", payload: selectedUser });
    } catch (err) {
      console.error("Error creating chat:", err);
    }
  };

  return (
    <div className="search" style={{ maxWidth: "400px", margin: "auto" }}>
      <div
        className="searchForm"
        style={{
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column", // Stack the search box on top of the user list
        }}
      >
        <input
          type="text"
          placeholder="Find a user"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          style={{
            width: "90%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ddd",
          }}
        />
        {err && <span>Unable to fetch users!</span>}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column", // Stack users vertically
          overflowY: "auto", // Enable vertical scrolling
          maxHeight: "450px", // Set a fixed height for the scrollable container
        }}
      >
        {users
          .filter((user) => {
            const userHasChat = userChats.some((chatId) => chatId.includes(user.uid));
            return (
              user.uid !== currentUser.uid &&
              (userHasChat ||
                username === "" ||
                user.displayName.toLowerCase().includes(username.toLowerCase()))
            );
          })
          .map((user) => {
            const combinedId =
              currentUser.uid > user.uid
                ? currentUser.uid + user.uid
                : user.uid + currentUser.uid;

            return (
              <div
                className="userChat"
                key={user.uid}
                onClick={() => handleSelect(user)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  cursor: "pointer",
                  borderBottom: "1px solid #ddd", // Divider between users
                }}
              >
                <div
                  className="emojiAvatar"
                  style={{
                    marginRight: "10px",
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={User}
                    alt="User Icon"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div
                  className="userChatInfo"
                  style={{
                    display: "flex",
                    flexDirection: "column", // Ensure the text stays vertically aligned
                    justifyContent: "left",
                    flexGrow: 1,
                  }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap", // Prevent wrapping text
                      overflow: "hidden",
                      textOverflow: "ellipsis", // Add ellipsis if the text overflows
                      maxWidth: "180px", // Limit the width of the text
                    }}
                  >
                    {user.displayName}
                  </span>
                  {unreadMessages[combinedId] && (
                    <span
                      style={{
                        color: "white",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        padding: "2px 6px",
                        fontSize: "12px",
                        marginTop: "5px",
                      }}
                    >
                      New
                    </span>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Search;
