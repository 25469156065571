import React, { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";

const Register = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false); // New state for success message
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const displayName = e.target[0].value;
    const email = e.target[1].value;
    const password = e.target[2].value;
    
    try {
      // Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      // Update user profile with display name
      await updateProfile(res.user, {
        displayName,
      });

      // Create user in Firestore without photoURL
      await setDoc(doc(db, "users", res.user.uid), {
        uid: res.user.uid,
        displayName,
        email,
      });

      // Create empty user chats in Firestore
      await setDoc(doc(db, "userChats", res.user.uid), {});

      // Set success message
      setSuccess(true);

      // Redirect to login page after a brief delay
      setTimeout(() => {
        navigate("/login");
      }, 2000); // Wait 2 seconds before redirecting

    } catch (err) {
      console.log(err);
      setErr(true);
      setLoading(false);
    }
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <span className="logo">AVACS Chat</span>
        <span className="title">Register</span>
        <form onSubmit={handleSubmit}>
          <input required type="text" placeholder="display name" />
          <input required type="email" placeholder="email" />
          <input required type="password" placeholder="password" />
          <button disabled={loading}>Sign up</button>
          
          {err && <span>Something went wrong</span>}
          {success && <span className="successMessage">Successfully registered! Redirecting to login...</span>}
        </form>
        <p>
          You do have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
