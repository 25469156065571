import React, { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";

const Messages = ({ messages }) => {
  const { currentUser } = useContext(AuthContext);
  const { data } = useContext(ChatContext);
  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="messages">
      {messages.map((message) => (
        <div
          key={message.id}
          className={`message ${message.senderId === currentUser.uid && "owner"}`}
        >
          <div className="messageInfo">
            {/* Show "You" for the logged-in user, otherwise show the other user's name */}
            <span>
              {message.senderId === currentUser.uid
                ? "You" // Display "You" instead of the username for the logged-in user
                : data.user?.displayName || "Unknown User"}
            </span>
            
          </div>
          <div className="messageContent">
            <p>{message.text}</p>
            {message.img && <img src={message.img} alt="Attachment" />}
          </div>
        </div>
      ))}
      <div ref={ref} />
    </div>
  );
};

export default Messages;
