import React, { useContext, useState, useEffect } from "react";
import Cam from "../img/cam.png";
import Add from "../img/add.png";
import More from "../img/more.png";
import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../context/ChatContext";
import { db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";

const Chat = () => {
  const { data } = useContext(ChatContext); // Access the selected user and chatId from context
  const [messages, setMessages] = useState([]); // Store chat messages
  const [showAddUser, setShowAddUser] = useState(false); // State for showing add user modal
  const [showMoreOptions, setShowMoreOptions] = useState(false); // State for showing dropdown menu

  useEffect(() => {
    // Listen for real-time updates to chat messages
    if (data?.chatId) {
      const unsubscribe = onSnapshot(doc(db, "chats", data.chatId), (doc) => {
        if (doc.exists()) {
          setMessages(doc.data().messages);
        }
      });

      // Cleanup listener
      return () => unsubscribe();
    }
  }, [data?.chatId]);

  if (!data?.user) return <div>Loading...</div>;

  return (
    <div className="chat">
      <div className="chatInfo">
        {/* Display the selected user’s name */}
        <span>{data.user.displayName}</span>
        <div className="chatIcons">
          {/* <img
            src={Cam}
            alt="camera icon"
            onClick={() => alert("Starting video call...")}
          />
          <img
            src={Add}
            alt="add icon"
            onClick={() => setShowAddUser((prev) => !prev)} 
          />
          <img
            src={More}
            alt="more icon"
            onClick={() => setShowMoreOptions((prev) => !prev)} 
          /> */}
        </div>
      </div>

      {/* Add User Modal */}
      {/* {showAddUser && (
        <div className="addUserModal">
          <h3>Add User</h3>
          <input
            type="text"
            placeholder="Enter username or email"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                alert(`Adding user: ${e.target.value}`); 
                setShowAddUser(false);
              }
            }}
          />
          <button onClick={() => setShowAddUser(false)}>Cancel</button>
        </div>
      )} */}

      {/* Dropdown Menu */}
      {/* {showMoreOptions && (
        <div className="dropdownMenu">
          <ul>
            <li onClick={() => alert("Settings clicked")}>Settings</li>
            <li onClick={() => alert("Delete chat clicked")}>Delete Chat</li>
            <li onClick={() => alert("Report user clicked")}>Report User</li>
            <li onClick={() => alert("Block User clicked")}>Block User</li>
            <li onClick={() => alert("View Contact clicked")}>View Contact</li>
          </ul>
        </div>
      )} */}

      {/* Display the messages */}
      <Messages messages={messages} />

      {/* Message input */}
      <Input chatId={data.chatId} />
    </div>
  );
};

export default Chat;
