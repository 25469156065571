import React from 'react';
import Sidebar from '../components/Sidebar';
import Chat from '../components/Chat';

const Home = () => {
  return (
    <div className="home">
      <div className="container">
        {/* Sidebar and Chat are wrapped inside a container */}
        <Sidebar />
        <Chat />
      </div>
    </div>
  );
};

export default Home;
